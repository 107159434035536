import { ColumnDef } from '@tanstack/react-table';
import { getCompanies, getG360Token } from 'api/auth/auth';
import { GetCompaniesParams, OktaGroup } from 'api/auth/schema';
import { PageTitleGranite } from 'components';
import { ServerPaginatedTable } from 'components/Table/ServerPaginatedTable';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { GraniteLabel } from 'components/V2/Label/GraniteLabel';
import { useAuthUser } from 'hooks/useAuthUser';
import { usePaginatedTable } from 'hooks/usePaginatedTable';
import { ContentLayout } from 'layouts/ContentLayout/ContentLayout';
import { useCallback, useState } from 'react';
import { TicketIdBadge } from 'screens/TechExpress/TechExpress.styles';
import Searchbar from 'components/Table/SearchBar';
import { useModal } from 'hooks/useModal';
import { ManageCompanyDialog } from './ManageCompanyModal';

const Companies: React.FC = () => {
  const { group_id, companyName } = useAuthUser();
  const [search, setSearch] = useState('');
  const getCompaniesQueryFn = useCallback(
    (args?: GetCompaniesParams) =>
      getCompanies({
        ...(search ? { search } : {}),
        ...args,
      }),
    [search],
  );
  const {
    data: tablePageData,
    refetch,
    ...paginatedTableProps
  } = usePaginatedTable<OktaGroup>(
    getCompaniesQueryFn,
    {
      search,
    },
    [
      'auth',
      'companies',
      {
        search,
      },
    ],
    { refetchOnMount: true },
  );

  const switchGroup = async (group: OktaGroup) => {
    const data = await getG360Token(group.group_id);
    localStorage.setItem(
      'g360-token-storage',
      JSON.stringify({ ...data, group }),
    );
    window.location.href = '/';
  };

  const CompaniesHeaders: ColumnDef<OktaGroup>[] = [
    {
      header: 'Company macnum',
      // TODO: handle more than one cw_link
      cell: (row) => (
        <TicketIdBadge>
          {row.row.original.profile.info?.cw_links?.[0]?.macnum}
        </TicketIdBadge>
      ),
      enableSorting: false,
    },
    {
      header: 'Company name',
      accessorKey: 'display_name',
      enableSorting: false,
      cell: (row) => (
        <div className="text-sm font-medium">
          {row.row.original.display_name ?? row.row.original.profile.name}
        </div>
      ),
    },
    {
      header: 'Actions',
      enableSorting: false,
      cell: (row) => {
        return (
          <div className="text-sm font-medium text-content-base-default">
            {row.row.original.group_id === group_id ? (
              'Current company'
            ) : (
              <GraniteButton
                onClick={(e) => {
                  e.stopPropagation();
                  switchGroup(row.row.original);
                }}
                title=""
                size="small"
                variant="secondary"
              >
                Switch to this company
              </GraniteButton>
            )}
          </div>
        );
      },
    },
  ];

  const { open, openWithProps, ...modalProps } = useModal<{
    groupId: string;
  }>();

  const handleRowClick = (row: { original: { group_id: string } }) => {
    openWithProps({ groupId: row.original.group_id });
  };

  return (
    <ContentLayout>
      <div className="flex w-full flex-col items-start justify-start">
        <PageTitleGranite title="Companies" className="w-full items-center">
          <div className="my-auto">
            <GraniteButton
              size="large"
              variant="primary"
              onClick={() => openWithProps()}
            >
              Add company
            </GraniteButton>
          </div>
        </PageTitleGranite>
        <div className="mb-8 mt-6 flex flex-col items-start justify-start gap-2">
          <div>
            <GraniteLabel className="text-base font-bold">
              Current company
            </GraniteLabel>
            <h1 className="text-2xl font-bold text-content-base-default">
              {companyName}
            </h1>
          </div>
          <p className="text-base font-semibold text-content-base-default">
            You are currently accessing Granite360 as a Granite Super Admin from{' '}
            {companyName}.
          </p>
        </div>
        <div className="flex w-full flex-col items-start justify-start gap-12">
          <Searchbar
            placeholder="Search by macnum or company name"
            onQueryClear={() => setSearch('')}
            onSearch={setSearch}
          />
          <ServerPaginatedTable
            data={tablePageData}
            columns={CompaniesHeaders}
            title="Companies"
            handleRowClick={handleRowClick}
            {...paginatedTableProps}
          />
        </div>
        <ManageCompanyDialog
          {...modalProps}
          onClose={function (): void {
            modalProps.close();
          }}
          onCompanySuccess={refetch}
        />
      </div>
    </ContentLayout>
  );
};

export default Companies;
